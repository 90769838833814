@import url("https://fonts.googleapis.com/css?family=Gothic+A1:400,700,900&display=swap");
:root {
  --footer-color: #3b3b3bd0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body {
  font-family: "Gothic A1", sans-serif;
}
/* Default Styles */
.section-title , .slogan {
  font-size : 13px;
}
h1,
h2 {
  font-weight: 300;
  letter-spacing: 5px;
}
/* --------Header */
header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.158), rgba(0, 0, 0, 0.158)),
    url(headerBG.jpg);
  height: 100vh;
  background-position: 20% 50% ;
  background-position: center;
  background-size: cover;
}

.slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  width:100%;
  text-align: center;
}

.slogan h1 {
  color: rgb(255, 211, 211);
  text-transform: uppercase;
  word-spacing: 4px;
  margin-top: 0;
}



/* --------Navigation Bar */

.nav-bar {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  background-color: rgb(223, 232, 241);
  box-shadow: 0 4px 8px 0 rgba(39, 39, 39, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  z-index: 1;
}

.navigation-logo-cart {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}
.company-logo {
  height: 100%;
}
.nav-icon,
.cart-navigation {
  font-size: 2em;
  cursor: pointer;
}
/*--------Sliding menu */

.no-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(223, 232, 241, 0.418);
  z-index: 2;
  visibility: hidden;
}

.yes-overlay {
  visibility: visible;
}

.sliding-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: 3;
  background: rgb(223, 232, 241);
  transition: all 0.3s linear;
  padding: 1rem;
  transform: translateX(100%);
}

.showCart {
  transform: translateX(0%);
}

.hideCart {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}
.sliding-menu h1,
.total-container h3 {
  text-align: center;
  font-weight: bold;
}

/*  Overlay  */

/*  Items added to cart section */

.cart-items {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  flex-grow: 2;
}

.cart-addedImg {
  width: 20%;
  height: 100%;
}

.price {
  letter-spacing: 1px;
}
.input-quantity input {
  width: 3rem;
  height: 1.5rem;
  outline: none;
}

.remove-container span {
  display: block;
}
/* Size Buttons */

.size-choice {
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  max-width: 150px;
}
.size-choice input[type="radio"] {
  display: none;
  cursor: pointer;
}
.size-choice label {
  display: inline-block;
  border: 2px solid black;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.size-choice input[type="radio"]:checked + label {
  background-color: #000;
  color: #fff;
}

.remove-current-item {
  cursor: pointer;
}

.remove-current-item:hover {
  color: rgba(22, 22, 22, 0.459);
}

.total-container {
  margin-top: 2.5rem;
}

/* quantity incrementer style */
.quantity-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-quantity-container {
  width: 60%;
  border-radius: 5px;
  border: 1px solid #5756562f;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.quantity {
  width: 100%;
  height: 100%;
  text-align: center;
  outline: none;
  align-self: center;
  background: none;
  border: none;
}
.minus:hover {
  color: red;
}
.plus:hover {
  color: green;
}
.minus,
.plus {
  padding: 0.5rem;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
  outline: none;
}

.total-amount {
  letter-spacing: 2px;
}
/* Product Showcase */
main {
  padding: 4rem 0;
}

.section-title h1 {
  text-align: center;
  margin-bottom: 5rem;
  text-transform: capitalize;
}
.product-container {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 1800px;
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  column-gap: 1em;
  gap: 30px;
  list-style: none;
}

.product {
  box-shadow: 0 4px 8px 0 rgba(39, 39, 39, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  position: relative;
}

.image-container img {
  display: block;
  width: 100%;
  height: 100%;
}



.addTo-cart {
  position: absolute;
  width: 50%;
  right: 25%;
  top: 90%;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  background: #3498db;
  border-radius: 2px;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  transition: all 0.3s linear;
  transform: translateY(200%);
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.2);
}
.image-container:hover .product-image {
  opacity: 0.5;
}
.image-container:hover .addTo-cart {
  transform: translateY(5%);
  transition: all 0.3s linear;
}

.image-container:hover .addTo-cart:hover {
  color: rgb(53, 52, 52);
}

/* -------- Footer */
footer {
  padding: 20px;
  background-color: rgb(206, 224, 241);
  color: var(--footer-color);
}

.inner-footer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.inner-footer h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
}

.inner-footer div {
  padding: 2rem;
}
.about-section p {
  margin-top: 1rem;
}
.socials-section {
  list-style: none;
  margin-top: 1rem;
}

.contact-section p {
  margin-top: 1rem;
}
.links {
  text-decoration: none;
  color: var(--footer-color);
  display: block;
  margin-top: 0.5rem;
  text-transform: capitalize;
}

.links:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .inner-footer {
    display: block;
  }
}

@media screen and (max-width:500px){
  .navigation-logo-cart {
    height:50%;
    font-size:1em;
  }
}
@media screen and (max-width: 300px ){
  .product-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}


@media screen and (min-width: 768px) {
  .sliding-menu-container {
    width: 30vw;
    width: 600px;
  }
}
