@import "https://fonts.googleapis.com/css?family=Gothic+A1:400,700,900&display=swap";
:root {
  --footer-color: #3b3b3bd0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Gothic A1, sans-serif;
}

.section-title, .slogan {
  font-size: 13px;
}

h1, h2 {
  letter-spacing: 5px;
  font-weight: 300;
}

header {
  height: 100vh;
  background-image: linear-gradient(#00000028, #00000028), url("headerBG.5b7d4b10.jpg");
  background-position: center;
  background-size: cover;
}

.slogan {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.slogan h1 {
  color: #ffd3d3;
  text-transform: uppercase;
  word-spacing: 4px;
  margin-top: 0;
}

.nav-bar {
  height: 70px;
  width: 100%;
  z-index: 1;
  background-color: #dfe8f1;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 4px 8px #27272733, 0 6px 20px #00000030;
}

.navigation-logo-cart {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
}

.company-logo {
  height: 100%;
}

.nav-icon, .cart-navigation {
  cursor: pointer;
  font-size: 2em;
}

.no-overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  background-color: #dfe8f16b;
  position: fixed;
  top: 0;
  right: 0;
}

.yes-overlay {
  visibility: visible;
}

.sliding-menu-container {
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #dfe8f1;
  padding: 1rem;
  transition: all .3s linear;
  position: fixed;
  top: 0;
  right: 0;
  overflow: scroll;
  transform: translateX(100%);
}

.showCart {
  transform: translateX(0%);
}

.hideCart {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.sliding-menu h1, .total-container h3 {
  text-align: center;
  font-weight: bold;
}

.cart-items {
  justify-content: flex-start;
  margin-top: 2rem;
  display: flex;
}

.product-details {
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-around;
  padding: 1rem;
  display: flex;
}

.cart-addedImg {
  width: 20%;
  height: 100%;
}

.price {
  letter-spacing: 1px;
}

.input-quantity input {
  width: 3rem;
  height: 1.5rem;
  outline: none;
}

.remove-container span {
  display: block;
}

.size-choice {
  max-width: 150px;
  justify-content: space-around;
  display: flex;
  overflow: hidden;
}

.size-choice input[type="radio"] {
  cursor: pointer;
  display: none;
}

.size-choice label {
  cursor: pointer;
  border: 2px solid #000;
  margin-right: 10px;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  display: inline-block;
}

.size-choice input[type="radio"]:checked + label {
  color: #fff;
  background-color: #000;
}

.remove-current-item {
  cursor: pointer;
}

.remove-current-item:hover {
  color: #16161675;
}

.total-container {
  margin-top: 2.5rem;
}

.quantity-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner-quantity-container {
  width: 60%;
  border: 1px solid #5756562f;
  border-radius: 5px;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

.quantity {
  width: 100%;
  height: 100%;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  align-self: center;
}

.minus:hover {
  color: red;
}

.plus:hover {
  color: green;
}

.minus, .plus {
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: .5rem;
}

.total-amount {
  letter-spacing: 2px;
}

main {
  padding: 4rem 0;
}

.section-title h1 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 5rem;
}

.product-container {
  max-width: 1800px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  gap: 30px;
  margin: 50px auto 0;
  padding: 1rem;
  list-style: none;
  display: grid;
}

.product {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px #27272733, 0 6px 20px #00000030;
}

.image-container img {
  width: 100%;
  height: 100%;
  display: block;
}

.addTo-cart {
  width: 50%;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  background: #3498db;
  border: none;
  border-radius: 2px;
  padding: .5rem;
  font-weight: bold;
  transition: all .3s linear;
  position: absolute;
  top: 90%;
  right: 25%;
  transform: translateY(200%);
  box-shadow: 0 3px 17px #0003;
}

.image-container:hover .product-image {
  opacity: .5;
}

.image-container:hover .addTo-cart {
  transition: all .3s linear;
  transform: translateY(5%);
}

.image-container:hover .addTo-cart:hover {
  color: #353434;
}

footer {
  color: var(--footer-color);
  background-color: #cee0f1;
  padding: 20px;
}

.inner-footer {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
}

.inner-footer h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
}

.inner-footer div {
  padding: 2rem;
}

.about-section p {
  margin-top: 1rem;
}

.socials-section {
  margin-top: 1rem;
  list-style: none;
}

.contact-section p {
  margin-top: 1rem;
}

.links {
  color: var(--footer-color);
  text-transform: capitalize;
  margin-top: .5rem;
  text-decoration: none;
  display: block;
}

.links:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .inner-footer {
    display: block;
  }

  .navigation-logo-cart {
    height: 50%;
    font-size: 1em;
  }
}

@media screen and (max-width: 300px) {
  .product-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 768px) {
  .sliding-menu-container {
    width: 30vw;
    width: 600px;
  }
}

/*# sourceMappingURL=index.aed5fc94.css.map */
